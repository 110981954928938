import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var FeatureCommService = /** @class */ (function () {
    function FeatureCommService() {
        this.types = [];
        this.industries = [];
        this.sidekickFeatureList = [];
        this.selectedFeatureSubject = new Subject();
        this.selectedFeature$ = this.selectedFeatureSubject.asObservable();
        this.retainTab = false;
        this.categoryTypeTempArr = [];
        this.categoryTypeIDTempArr = [];
        this.totalHotspot = 0;
        this.showSyncPopup = false;
        this.deletedManualHotspotIdArray = [];
    }
    FeatureCommService.prototype.setSelectedFeature = function (feature) {
        this.selectedFeatureSubject.next(feature);
        this.selectedFeatureId = feature.id;
    };
    FeatureCommService.prototype.setFeatureList = function (featureList) {
        this.featureList = featureList;
    };
    FeatureCommService.prototype.getFeatureList = function () {
        return this.featureList;
    };
    FeatureCommService.prototype.setSidekickFeatures = function (sidekickFeatures) {
        this.sidekickFeatureList = sidekickFeatures;
    };
    FeatureCommService.prototype.getSidekickFeatures = function () {
        return this.sidekickFeatureList;
    };
    FeatureCommService.prototype.setDataChanged = function (flag) {
        if (this.isDataChanged !== flag) {
            this.isDataChanged = flag;
        }
    };
    FeatureCommService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureCommService_Factory() { return new FeatureCommService(); }, token: FeatureCommService, providedIn: "root" });
    return FeatureCommService;
}());
export { FeatureCommService };
